import type {
  ApolloLink,
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  NormalizedCacheObject,
  Observable,
  ObservableQuery,
  OperationVariables,
  QueryOptions,
  WatchFragmentOptions,
  WatchFragmentResult,
  WatchQueryOptions,
} from '@apollo/client/core'
import {
  createHttpLink,
  from,
  InMemoryCache,
  ApolloClient,
} from '@apollo/client/core'
import introspectionResult from 're-client/graphql/introspection-result'
import config from 're-client/config/environment'
import type { TypedTypePolicies } from 're-client/graphql/apollo-helpers'
import { capitalize } from '@ember/string'
import { waitFor } from '@ember/test-waiters'
import { onError } from '@apollo/client/link/error'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { runInDebug } from '@ember/debug'
import Service, { service } from '@ember/service'
import type { Log } from '@blakeelearning/log'

runInDebug(() => {
  loadDevMessages()
  loadErrorMessages()
})

const {
  APP: { apiEndpoint, apollo },
} = config

let apolloURL = '/graphql'

if (apiEndpoint) {
  apolloURL = new URL(apolloURL, apiEndpoint).href
}

const typePolicies: TypedTypePolicies = {
  Student: {
    fields: {
      name: {
        read(_, { readField }) {
          const firstName = readField({ fieldName: 'firstName' })
          const lastName = readField({ fieldName: 'lastName' })
          if (typeof firstName === 'string' && typeof lastName === 'string') {
            return `${capitalize(firstName)} ${capitalize(lastName.charAt(0))}.`
          }

          return undefined
        },
      },
      remoteId: {
        read(_, { readField }) {
          const remoteId = readField({ fieldName: 'id' })
          if (typeof remoteId === 'string') {
            return remoteId
          }

          return undefined
        },
      },
      dnaHash: {
        read(_, { readField }) {
          const dna = readField({ fieldName: 'dna' })

          if (
            dna !== null &&
            typeof dna === 'object' &&
            '__typename' in dna &&
            dna.__typename === 'AvatarDna'
          ) {
            const dnaClone = { ...dna }
            delete dnaClone.__typename
            const dnaHash = JSON.stringify(dnaClone)
            return dnaHash
          }

          return undefined
        },
      },
    },
  },
  AssignmentTaskLesson: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['lessons.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskSpelling: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['spelling.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskClinkerCastle: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['storylands.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskDrivingTests: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const category = readField({ fieldName: 'category' })
          return ['driving-tests.quiz', category, lessonId]
        },
      },
    },
  },
}

export default class ApolloService extends Service {
  @service
  declare log: Log

  #client: ApolloClient<NormalizedCacheObject>

  constructor(properties?: object) {
    super(properties)

    const cache = new InMemoryCache({
      typePolicies,
      /**
       * Declare Possible Types
       *
       * This is required for when we use fragments on union types or interfaces
       * Otherwise Apollo doesn't know what to do, and strips all the fields from the response
       * @see {@link https://github.com/apollographql/apollo-client/issues/7050}
       * @see {@link https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces}
       */
      possibleTypes: introspectionResult.possibleTypes,
    })

    const httpLink = createHttpLink({
      uri: apolloURL,
      credentials: 'include',
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, ...error }) => {
          this.log.error(message, error)
        })
      }

      if (networkError) {
        this.log.error(networkError)
      }
    })

    this.#client = new ApolloClient({
      cache,
      link: from([errorLink, httpLink]),
      devtools: {
        enabled: apollo.connectToDevTools ?? false,
      },
    })
  }

  get cache() {
    return this.#client.cache
  }

  setLink(link: ApolloLink) {
    this.#client.setLink(link)
  }

  override willDestroy() {
    void this.#client.clearStore()
  }

  @waitFor
  async mutate<
    T = unknown,
    TVariables extends OperationVariables = OperationVariables,
  >(options: MutationOptions<T, TVariables>): Promise<FetchResult<T>> {
    return this.#client.mutate(options)
  }

  @waitFor
  async query<
    T = unknown,
    TVariables extends OperationVariables = OperationVariables,
  >(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> {
    return this.#client.query(options)
  }

  watchQuery<
    T = unknown,
    TVariables extends OperationVariables = OperationVariables,
  >(options: WatchQueryOptions<TVariables, T>): ObservableQuery<T, TVariables> {
    return this.#client.watchQuery(options)
  }

  watchFragment<TFragmentData = unknown, TVariables = OperationVariables>(
    options: WatchFragmentOptions<TFragmentData, TVariables>,
  ): Observable<WatchFragmentResult<TFragmentData>> {
    return this.#client.watchFragment(options)
  }
}

declare module '@ember/service' {
  interface Registry {
    apollo: ApolloService
  }
}

import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type SpellingService from 're-client/services/spelling'
import ky from 'ky'

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

interface SpellingResults {
  spelling_results: {
    map: {
      best_lesson_quiz_scores: unknown
      best_map_quiz_percent: number
    }
    all_maps: {
      first_lesson_quiz_completed_at: string
      first_lesson_quiz_number: number
      lesson_quizzes_average_score: number
      best_lesson_quiz_score: number
    }
  }
}

export default class IndexRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare spelling: SpellingService

  override async model() {
    const {
      currentMap,
      nextMapId,
      previousMapId,
      canPlayQuizForMap,
      isQuizComplete,
      isOnFinalMap,
    } = this.spelling
    const mapId = currentMap

    const avatarJsonRequest = this.caperLoader.avatarJsonRequest()
    const quizComplete = isQuizComplete
    const lessonNumber = this.studentProgress.spellingCurrentLessonNumber

    const { spelling_results: spellingResults } = await ky
      .get(`spelling_results/${mapId.toFixed()}`, {
        credentials: 'include',
        prefixUrl,
      })
      .json<SpellingResults>()

    const { map, all_maps: allMaps } = spellingResults

    const {
      first_lesson_quiz_completed_at: initialLessonCompletedAt,
      first_lesson_quiz_number: initialLessonNumber,
    } = allMaps

    const { best_map_quiz_percent: highestPercentage } = map

    const lessonQuizSummary = {
      average_correct_count: allMaps.lesson_quizzes_average_score,
      highest_correct_count: allMaps.best_lesson_quiz_score,
      lesson_quizzes: map.best_lesson_quiz_scores,
    }

    const variables = {
      student: this.user.student,
      map_id: mapId,
      canPlayQuizForMap,
      lesson_number: lessonNumber, // deprecated, remove once https://github.com/blake-education/caper-activities/pull/3862 is released
      quiz_complete: quizComplete,
      quiz_id: this.studentProgress.spellingCurrentLesson, // deprecated, remove once https://github.com/blake-education/caper-activities/pull/3862 is released
      map_number: mapId,
      lesson_quiz_summary: lessonQuizSummary,
      current_lesson_number: lessonNumber, // deprecated, remove once https://github.com/blake-education/caper-activities/pull/3862 is released
      initial_lesson_completed_at: initialLessonCompletedAt,
      initial_lesson_number: initialLessonNumber,
      highest_percentage: highestPercentage,
      progressLessonNumber: this.studentProgress.spellingCurrentLessonNumber,
      progressLessonPosition: this.studentProgress.spellingCurrentLesson,
    }

    const mapUrl = this.urlMaker.urlForInteractive('spelling/map', mapId)
    const manifests = [mapUrl, avatarJsonRequest]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      isOnFinalMap,
      mapId,
      nextMapId,
      previousMapId,
      interactiveConfig,
    }
  }
}

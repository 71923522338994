import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import type LocationTrackerService from 're-client/services/location-tracker'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type UrlMakerService from 're-client/services/url-maker'
import type CaperLoaderService from 're-client/services/caper-loader'
import type StorylandsService from 're-client/services/storylands'
import type Transition from '@ember/routing/transition'
import type { InteractiveConfig } from '@blakeelearning/content-loader-core'

/**
 * Faux-model for the StorylandsLessonsMapRouter
 *
 * @class StorylandsLessonsMapObject
 * @property {Number} id The map id
 */
export class StorylandsMapRouteModel {
  lastMap = config.studentProgress.progress.storylands.lastMap

  id: number

  constructor({ map_id }: RouteParams) {
    this.id = Number(map_id)
  }

  get isFinishedStorylands() {
    return this.id > this.lastMap
  }

  get previousMapId() {
    return clamp(this.id - 1, 1, this.lastMap)
  }

  get nextMapId() {
    return this.id + 1
  }
}

function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

interface RouteParams {
  map_id: string
}

interface RouteModel {
  mapId: number
  isFinishedStorylands: boolean
  nextMapId: unknown
  previousMapId: unknown
  interactiveConfig: InteractiveConfig
}

/**
 * Loads student progress and checks to see if the map can be played according
 * to the progress loaded.
 *
 * @class StorylandsMapRoute
 */
export default class StorylandsMapRoute extends Route<
  RouteModel | undefined,
  RouteParams
> {
  @service
  declare locationTracker: LocationTrackerService

  @service
  declare releaseChecker: ReleaseChecker

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare urlMaker: UrlMakerService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare storylands: StorylandsService

  override beforeModel() {
    if (this.studentProgress.isAreaLocked('storylands')) {
      void this.router.transitionTo('index')
    }
  }

  /**
   * Builds the map model from the map id parameter
   *
   * @instance
   * @memberOf StorylandsMapRoute
   *
   * @param  {Object} params - The url params
   * @return {Map} The model for the map
   */
  override async model(params: RouteParams): Promise<RouteModel | undefined> {
    const {
      id: mapId,
      isFinishedStorylands,
      nextMapId,
      previousMapId,
    } = new StorylandsMapRouteModel(params)

    if (isFinishedStorylands) {
      void this.router.transitionTo(
        'storylands.map',
        config.studentProgress.progress.storylands.lastMap,
      )
      return
    }
    // playability *only* accounts for if the currentMap is greater than the
    // map asked for in the route, it does not care about overflows ie asking for map 1000
    if (!this.storylands.canPlayMap(mapId)) {
      void this.router.transitionTo(
        'storylands.map',
        this.studentProgress.storylandsCurrentMap,
      )
      return
    }

    const avatarJsonRequest = this.caperLoader.avatarJsonRequest()
    const quizComplete = this.storylands.isQuizComplete(mapId)

    const variables = {
      student: this.user.student,
      lesson_number: this.studentProgress.storylandsCurrentLesson,
      map_number: mapId,
      quiz_complete: quizComplete,
    }

    const mapUrl = this.urlMaker.urlForInteractive('clinker-castle/map', mapId)
    const manifests = [mapUrl, avatarJsonRequest]
    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      mapId,
      isFinishedStorylands,
      nextMapId,
      previousMapId,
      interactiveConfig,
    }
  }

  override afterModel(model: RouteModel, transition: Transition) {
    const currentRouteName = transition.to?.name
    this.locationTracker.setCurrentRoute(currentRouteName, [model.mapId])

    void this.router.transitionTo('storylands.map', model.mapId)
  }
}

import progressionSequence from '@blakeelearning/progression-sequence-readingeggs/dist/progression.json'
import {
  Framework,
  ReadingActivityEnum,
  type ReadingActivity,
} from 're-client/graphql/graphql'

export default function* readingActivities() {
  for (const step of progressionSequence) {
    if (step.precinct === 'reading') {
      let activityType: ReadingActivityEnum
      let framework: Framework

      switch (step.activityType) {
        case 'book':
          activityType = ReadingActivityEnum.Book
          break

        case 'interactive':
          activityType = ReadingActivityEnum.Interactive
          break
        case 'quiz':
          activityType = ReadingActivityEnum.Quiz
          break

        case 'video':
          activityType = ReadingActivityEnum.Video
          break

        default:
          throw new Error(`Unknown activity type: ${step.activityType}`)
      }

      switch (step.framework) {
        case 'caper':
          framework = Framework.Caper
          break

        case 'jester':
          framework = Framework.Jester
          break

        default:
          throw new Error(`Unknown framework: ${step.framework}`)
      }

      yield {
        ...step,
        __typename: 'ReadingActivity',
        id: `${step.precinct}/map-${step.map}/lesson-${step.lessonInMap}/activity-${step.activityInLesson}`,
        activityType,
        framework,
      } satisfies ReadingActivity
    }
  }
}
